<template>
  <div class="race-participants">
    <b-card>
      <header class="d-flex align-items-center justify-content-between">
        <div>
          <h4>{{ race?.name }} Participants</h4>
          <p>you can draging the participants to change the order of them</p>
        </div>
        <b-button variant="primary" @click="updateParticipants">
          update participants
        </b-button>
      </header>

      <b-list-group class="race-paricipants__list">
        <draggable
          v-model="participants"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <transition-group type="transition" name="flip-list">
            <b-list-group-item
              v-for="(participant, index) in participants"
              :key="participant.id"
              class="race-participants__list--item"
              button
            >
              <div>
                <h3>End Position</h3>
                <h4>{{ index + 1 }}</h4>
              </div>

              <div>
                <h3>Jockey</h3>
                <h4>{{ participant.jockeyName }}</h4>
              </div>
              <div>
                <h3>Horse</h3>
                <h4>{{ participant.horseName }}</h4>
              </div>
              <div>
                <h3>Number</h3>
                <h4>{{ participant.number }}</h4>
              </div>

              <div>
                <h3>odds</h3>
                <h4>{{ participant.odd_form }}</h4>
              </div>
              <div>
                <h3>Total Amount</h3>
                <h4>{{ participant.total_amount }}</h4>
              </div>
              <div style="width: 156px">
                <h3>End Time</h3>
                <b-form-timepicker
                  v-model="participant.end_time"
                  size="sm"
                  locale="en"
                ></b-form-timepicker>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </b-list-group>
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import raceServices from "../services/races";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";
import draggable from "vuedraggable";
export default {
  mixins: [moduleItemMixin],
  components: { draggable },
  data() {
    return {
      participants: [],
      race: {},
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    async getParticipants() {
      const response = await raceServices.getRaceParticipants(
        this.$route.params.id
      );
      this.participants = response.data.data.sort(
        (a, b) => a.end_position - b.end_position
      );
    },
    async updateParticipants() {
      const data = this.participants.map((participant, index) => {
        return {
          id: participant.id,
          end_position: index + 1,
          raceId: this.$route.params.id,
          end_time: participant.end_time,
        };
      });
      const response = await raceServices.updateRaceParticipants(data);
      core.showSnackbar("success", response.data.message);
    },
    async getRace() {
      const response = await raceServices.findOne(this.$route.params.id);
      this.race = response.data.data;
    },
  },
  mounted() {
    core.index();
  },
  created() {
    this.getParticipants();
    this.getRace();
  },
};
</script>

<style lang="scss">
.race-participants {
  &__list {
    &--item {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      cursor: move !important;

      h3 {
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
        color: var(--iq-primary);
      }
      h4 {
        font-size: 14px;
        font-weight: normal;
        margin-top: 10px;
      }
      &:hover {
        background-color: var(--iq-primary);
        h3,
        h4 {
          color: #fff;
        }
      }
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

// .ghost {
//   opacity: 0.5;
//   background: #ccc;
// }
</style>
